import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPwd(props) {
  const classes = useStyles();
  const [error, setError] = React.useState({ isErr: false, msg: "" });
  const [load, setLoad] = React.useState(false);
  const [resent, setResent] = React.useState(false);
  const [stage, setStage] = React.useState(0);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Recovery
        </Typography>

        <form
          className={classes.form}
          onSubmit={async (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            let username = data.get("username");

            if (stage == 0) {
              try {
                setLoad(true);
                setError({ isErr: false });
                await Auth.forgotPassword(username);
                setStage(1);
                setLoad(false);
              } catch (error) {
                setError({ isErr: true, msg: error.message });
                setLoad(false);
                // console.log(error.message);
              }
            } else {
              let code = data.get("code");
              let new_password = data.get("new_password");
              setLoad(true);
              setError({ isErr: false });
              await Auth.forgotPasswordSubmit(username, code, new_password)
                .then(() => {
                  alert("Password Changed");
                  window.location.reload();
                })
                .catch((error) => {
                  setError({ isErr: true, msg: error.message });
                  setLoad(false);
                  // console.log(error.message);
                });
            }
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          {stage == 1 && (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Verification Code"
                name="code"
                type="number"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="new_password"
                label="New Password"
                name="new_password"
                type="password"
              />
            </div>
          )}
          {error.isErr && (
            <div className="ui negative message">
              <div className="header">Error</div>
              <p>{error.msg}</p>
            </div>
          )}
          <div className={classes.submit}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {stage == 0 ? <p>Send Recovery Code</p> : <p>Reset Password</p>}
            </Button>
            {load && <LinearProgress />}
          </div>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={props.goHome}
                style={{ cursor: "pointer" }}
              >
                Go back to Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
