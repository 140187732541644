import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Segment, Button, Form } from "semantic-ui-react";

import { Auth } from "aws-amplify";

const useStyles = makeStyles(theme => ({
  segment: {
    padding: theme.spacing(1),
    backgroundColor: "#FDFDFD"
  }
}));

export default function AccountInfo(props) {
  const classes = useStyles();
  const [error, setError] = React.useState({ isErr: false, msg: "" });
  const [success, setSuccess] = React.useState(false);

  return (
    <Grid.Column>
      <Segment className={classes.segment}>
        <h2>Personal Details</h2>
        <Form
          onSubmit={e => {
            e.preventDefault();
            const data = new FormData(e.target);

            let name = data.get("name");
            let phone = data.get("phone");

            Auth.currentAuthenticatedUser()
              .then(user => {
                return Auth.updateUserAttributes(user, {
                  name: name,
                  phone_number: phone
                });
              })
              .then(data => {
                // console.log(data);
                setSuccess(true);
              })
              .catch(err => {
                console.log(err);
                setError({ isErr: true, msg: err.message });
              });
          }}
        >
          <Form.Field>
            <label>Name</label>
            <input
              name="name"
              placeholder="Name"
              defaultValue={props.userInfo.name}
            />
          </Form.Field>
          <Form.Field>
            <label>Username</label>
            <input
              name="username"
              placeholder="Username"
              readOnly
              defaultValue={props.userInfo.username}
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input
              name="email"
              placeholder="Email"
              readOnly
              defaultValue={props.userInfo.email}
            />
          </Form.Field>
          <Form.Field>
            <label>Phone Number</label>
            <input
              name="phone"
              placeholder="+<code><phone number> eg:- +919911557070"
              defaultValue={props.userInfo.phone}
            />
          </Form.Field>

          {error.isErr && (
            <div className="ui negative message">
              <div className="header">Error</div>
              <p>{error.msg}</p>
            </div>
          )}
          {success && (
            <div className="ui positive message">
              <div className="header">Success</div>
              <p>Personal Details Updated</p>
            </div>
          )}

          <Button type="submit" primary>
            Update Details
          </Button>
        </Form>
      </Segment>
    </Grid.Column>
  );
}
