import React, { useRef, Component, PropTypes } from "react";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import PrintResults from "./components/PrintResults";
import {
  Progress,
  Grid,
  Message,
  Icon,
  Button,
  Divider,
  Header,
  Segment,
  Input,
  Form,
} from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import Amplify, { Storage, Auth } from "aws-amplify";
import { sessionDBWrite, sessionDBRead } from "./components/API";
import { createRows, createResultsList } from "./components/DrugSafety/Utils";
import Navbar from "./components/Navbar";
import Upload from "./components/DrugSafety/Upload";
import DSPResults from "./components/DrugSafety/DSPResults";
import DocTable from "./components/DrugSafety/DocTable";

const API_URL = "https://oaq0lwjwp9.execute-api.us-east-1.amazonaws.com/beta";
const queryDB = async (drug) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;

  const resultsResp = await fetch(`${API_URL}/dspquery`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      drug: drug,
    }),
  });
  const resultsBody = await resultsResp.json();
  // console.log(resultsBody);
  return resultsBody;
};

class DrugSafety extends React.Component {
  state = {
    predictions: [],
    length: 0,
    res: false,
    nores: false,
    isSess: false,
    sessId: "",
    rows: [],
    drug: "",
  };

  componentDidMount() {
    this.getStatusDB();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  //============= Session ================

  getStatusDB = async () => {
    // Read Session Data
    const responseBody = await sessionDBRead("drugsafety", this.state.sessId);
    // console.log(responseBody);
    // Format the Response as Row Data for the SessionTable
    const rows = await createRows(responseBody);
    // Sort rows
    await rows.sort(function (a, b) {
      let dateA = a.sessId.toLowerCase();
      let dateB = b.sessId.toLowerCase();
      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
    this.setState({ rows: rows });
    // console.log("Rows Updated:",rows);
  };

  onSessionToggle = () => {
    if (!this.state.isSess) {
      let timestamp = "DS" + new Date().getTime();
      this.setState({ isSess: true, sessId: timestamp, btn: "red" });
    } else {
      let timestamp = "DS" + new Date().getTime();
      this.setState({
        isSess: false,
        sessId: timestamp,
        btn: "blue",
      });
    }
    this.getStatusDB();
  };

  //============= Results ================
  onQuery = async () => {
    this.setState({ res: false });
    // Get Results
    const responseBody = await queryDB(this.state.drug);
    console.log(responseBody);

    if (responseBody == "404") {
      alert(`No Results Found for ${this.state.drug}`);
    } else {
      this.setState({
        predictions: responseBody,
        res: true,
        isSess: false,
      });
    }
  };

  //============= Make Predictions from Dashboard ================
  // dashPred = async (sessId, timestamp) => {
  //   // Call ResultsDB API for getting the input keys of the session
  //   const resultsBody = await resultsDB("braintumor", sessId);
  //   // console.log(resultsBody);
  //   const keys = await resultsBody.inputs;
  //
  //   // Updating the Session Table
  //   const sess_responseBody = await sessionDBWrite(
  //     "braintumor",
  //     sessId,
  //     timestamp,
  //     "InProgress"
  //   );
  //   // console.log("Session Response:", sess_responseBody);
  //
  //   // Making the prediction request
  //   const responseBody = serviceAPI("braintumor", sessId, keys);
  //   // console.log("Response:", responseBody);
  //
  //   this.getStatusDB();
  // };

  render() {
    return (
      <div>
        <Navbar>
          <h1>Drug Safety Reporting</h1>
          <Divider horizontal>
            <Header as="h2">
              <Icon name="bar chart" />
              Dashboard
            </Header>
          </Divider>
          <Button icon labelPosition="left" onClick={this.getStatusDB}>
            Refresh
            <Icon name="sync alternate" />
          </Button>

          {!this.state.res && (
            <Button
              icon
              style={{
                right: "20px",
                position: "absolute",
              }}
              labelPosition="left"
              color={this.state.isSess ? "red" : "blue"}
              onClick={this.onSessionToggle}
            >
              {!this.state.isSess ? (
                <div>Upload Documents</div>
              ) : (
                <div>Close Document Session</div>
              )}
              {!this.state.isSess ? (
                <Icon name="upload" />
              ) : (
                <Icon name="close" />
              )}
            </Button>
          )}

          <DocTable rows={this.state.rows} />

          <div
            ref={(el) => {
              this.el = el;
            }}
          />

          {!this.state.isSess && (
            <div>
              <Divider horizontal>
                <Header as="h2">
                  <Icon name="edit" />
                  Query Report
                </Header>
              </Divider>
              <center>
                <div style={{ width: "40vw" }}>
                  <Form onSubmit={this.onQuery}>
                    <Form.Field>
                      <Input
                        focus
                        name="drug-input"
                        placeholder="Enter the Drug Name"
                        onChange={async (e) => {
                          let drug = e.target.value;
                          await this.setState({
                            drug: drug,
                          });
                        }}
                        defaultValue={this.state.drug}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button
                        icon
                        labelPosition="right"
                        type="submit"
                        color="primary"
                      >
                        Get Report
                        <Icon name="search" />
                      </Button>
                    </Form.Field>
                  </Form>
                </div>
              </center>
              <div style={{ marginTop: "20px" }}></div>
            </div>
          )}

          {this.state.isSess && (
            <div>
              <Divider horizontal>
                <Header as="h2">
                  <Icon name="edit" />
                  Process Documents
                </Header>
              </Divider>
              <Segment raised>
                <IconButton
                  style={{
                    right: "15px",
                    position: "absolute",
                  }}
                  color="secondary"
                  onClick={() =>
                    this.setState({ res: false, isSess: false, btn: "blue" })
                  }
                >
                  <CloseIcon />
                </IconButton>
                <div style={{ marginTop: "50px" }}>
                  <Upload
                    sessId={this.state.sessId}
                    toggle={this.onSessionToggle}
                    getStatusDB={this.getStatusDB}
                    service="drugsafety"
                  />
                </div>
              </Segment>
            </div>
          )}

          {this.state.nores && (
            <Message icon>
              <Icon name="circle notched" error />
              <Message.Content>
                <Message.Header>Uploading...</Message.Header>
                Uploading your documents to our server
              </Message.Content>
            </Message>
          )}

          {this.state.res && (
            <Segment raised>
              <IconButton
                style={{
                  right: "15px",
                  position: "absolute",
                }}
                color="secondary"
                onClick={() =>
                  this.setState({ res: false, isSess: false, btn: "blue" })
                }
              >
                <CloseIcon />
              </IconButton>

              <div style={{ marginTop: "50px" }}>
                <DSPResults rows={this.state.predictions} />
              </div>
            </Segment>
          )}
        </Navbar>
      </div>
    );
  }
}

export default DrugSafety;
