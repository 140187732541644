import React from "react";
import Navbar from "./components/Navbar";

class P404 extends React.Component {
  render() {
    return (
      <div>
        <Navbar>
          <h1 className="ui header">404: Page Not Found</h1>
        </Navbar>
      </div>
    );
  }
}

export default P404;
