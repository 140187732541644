import React from "react";
import Navbar from "./components/Navbar";
import NLPInput from "./components/NLP/NLPInput";
import ResultsLS from "./components/NLP/ResultsLS";
import SessionTable from "./components/Vision/SessionTable";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import PrintResults from "./components/PrintResults";
import {
  Progress,
  Grid,
  Message,
  Icon,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";
import Amplify, { Storage, Auth } from "aws-amplify";
import {
  serviceDB,
  sessionDBWrite,
  sessionDBRead,
  resultsDB,
  serviceAPI,
} from "./components/API";
import { createRows } from "./components/Vision/Utils";

class LitSearch extends React.Component {
  state = {
    predictions: "",
    length: 0,
    res: false,
    loading: false,
    isSess: false,
    sessId: "",
    rows: [],
  };

  async componentDidMount() {
    this.getStatusDB();
    // let creds = await Auth.currentCredentials();
    // let userId = creds.identityId;
    // console.log(userId);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  //============= Session ================
  getStatusDB = async () => {
    // Get Session Data
    const responseBody = await sessionDBRead(
      "textsimilarity",
      this.state.sessId
    );
    // console.log(responseBody);
    const rows = await createRows(responseBody);
    await rows.sort(function (a, b) {
      let dateA = a.sessId.toLowerCase();
      let dateB = b.sessId.toLowerCase();
      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
    this.setState({ rows: rows });
    // console.log("Rows Updated:",rows);
  };

  onSessionToggle = () => {
    if (!this.state.isSess) {
      let timestamp = "LS" + new Date().getTime();
      this.setState({ isSess: true, sessId: timestamp, btn: "red" });
    } else {
      let timestamp = "LS" + new Date().getTime();
      this.setState({
        isSess: false,
        sessId: timestamp,
        btn: "blue",
      });
    }
    this.getStatusDB();
  };

  //============= Results ================
  getResults = async (sessId) => {
    this.setState({ res: false });
    // Get Results
    const responseBody = await resultsDB("textsimilarity", sessId);
    // console.log("Results: ", responseBody);
    this.setState({
      predictions: responseBody,
      res: true,
      isSess: false,
      sessId: sessId,
    });
  };

  //============= Make Predictions from Dashboard ================

  dashPred = async (sessId, timestamp) => {
    // Call ResultsDB API for getting the input keys of the session
    const resultsBody = await resultsDB("textsimilarity", sessId);
    // console.log(resultsBody);
    const keys = await resultsBody.inputs;

    // Updating the Session Table
    const sess_responseBody = await sessionDBWrite(
      "textsimilarity",
      sessId,
      timestamp,
      "InProgress"
    );
    // console.log("Session Response:", sess_responseBody);

    // Making the prediction request
    const responseBody = await serviceAPI("textsimilarity", sessId, keys);
    // console.log("Response:", responseBody);

    this.getStatusDB();
  };

  render() {
    return (
      <div>
        <Navbar>
          <h1>Covid-19 Literature Search</h1>
          <Divider horizontal>
            <Header as="h2">
              <Icon name="bar chart" />
              Dashboard
            </Header>
          </Divider>
          <Button icon labelPosition="left" onClick={this.getStatusDB}>
            Refresh
            <Icon name="sync alternate" />
          </Button>

          <SessionTable
            rows={this.state.rows}
            view={this.getResults}
            predict={this.dashPred}
          />

          <div
            ref={(el) => {
              this.el = el;
            }}
          />
          <Divider horizontal>
            <Header as="h2">
              <Icon name="edit" />
              Workspace
            </Header>
          </Divider>

          {!this.state.res && (
            <Button
              icon
              labelPosition="left"
              color={this.state.isSess ? "red" : "blue"}
              onClick={this.onSessionToggle}
            >
              {!this.state.isSess ? (
                <div>Start Session</div>
              ) : (
                <div>End Session</div>
              )}
              {!this.state.isSess ? <Icon name="play" /> : <Icon name="stop" />}
            </Button>
          )}

          {this.state.isSess && (
            <Segment raised>
              <NLPInput
                sessId={this.state.sessId}
                toggle={this.onSessionToggle}
                getStatusDB={this.getStatusDB}
                service="textsimilarity"
              />
            </Segment>
          )}

          {this.state.res && (
            <Segment raised>
              <IconButton
                style={{
                  right: "15px",
                  position: "absolute",
                }}
                color="secondary"
                onClick={() =>
                  this.setState({ res: false, isSess: false, btn: "blue" })
                }
              >
                <CloseIcon />
              </IconButton>
              <PrintResults
                title="Literature Search"
                sessId={this.state.sessId}
                service="textsimilarity"
              >
                <ResultsLS pred={this.state.predictions} />
              </PrintResults>
            </Segment>
          )}
        </Navbar>
      </div>
    );
  }
}

export default LitSearch;
