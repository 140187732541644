import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const LSData = ({
  id,
  abstract,
  source,
  title,
  publishTime,
  authors,
  journal,
  urls,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  var url = urls.split(";");
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" style={{ fontWeight: "bold" }}>
          {id}
        </TableCell>
        <TableCell component="th" scope="row">
          {source}
        </TableCell>
        <TableCell align="center">{title}</TableCell>
        <TableCell align="center">{publishTime}</TableCell>
        <TableCell align="center">{authors}</TableCell>
        <TableCell align="center">{journal}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Abstract
              </Typography>
              <p>{abstract}</p>
            </Box>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Links
              </Typography>
              {url.map((eachUrl) => (
                <>
                  <a href={`${eachUrl}`}>{eachUrl}</a>
                  <br />
                </>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default LSData;
