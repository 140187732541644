import { Auth } from "aws-amplify";

const API_URL = "https://oaq0lwjwp9.execute-api.us-east-1.amazonaws.com/beta";

// ========== Database APIs ==========
export const serviceDB = async (service, sessId, keys) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;
  // console.log(userId);

  const response = await fetch(`${API_URL}/upload`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      service: service,
      invocationId: sessId,
      keys: keys,
    }),
  });
  const responseBody = await response.json();

  return responseBody;
};

// Write Session Data
export const sessionDBWrite = async (service, sessId, timestamp, status) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;

  const response = await fetch(`${API_URL}/session`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      service: service,
      invocationId: sessId,
      status: status,
      timestamp: timestamp,
      action: "W",
    }),
  });
  const responseBody = await response.text();
  return responseBody;
};

// Read Session Data
export const sessionDBRead = async (service, sessId) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;
  // console.log(userId);

  const response = await fetch(`${API_URL}/session`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      service: service,
      invocationId: sessId,
      action: "R",
    }),
  });
  const responseBody = await response.json();
  return responseBody;
};

export const resultsDB = async (service, sessId) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;

  const resultsResp = await fetch(`${API_URL}/results`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      service: service,
      invocationId: sessId,
    }),
  });
  const resultsBody = await resultsResp.json();
  return resultsBody;
};

// ========== Service API ==========
export const serviceAPI = async (service, sessId, keys) => {
  // Get user authentication jwtToken
  let user = await Auth.currentAuthenticatedUser();
  const auth = await user.signInUserSession.idToken.jwtToken;

  // Get user identityId
  let creds = await Auth.currentCredentials();
  const userId = creds.identityId;

  const response = await fetch(`${API_URL}/${service}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    method: "POST",
    body: JSON.stringify({
      userId: userId,
      service: service,
      invocationId: sessId,
      keys: keys,
    }),
  });
  const responseBody = await response.text();
  return responseBody;
};
