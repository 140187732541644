import React from "react";
import Input from "@material-ui/core/Input";
import Dropzone from "react-dropzone";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {
  Message,
  Icon,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";
import Amplify, { Storage, Auth } from "aws-amplify";
import { serviceDB, sessionDBWrite, sessionDBRead, serviceAPI } from "../API";

const API_URL = "https://oaq0lwjwp9.execute-api.us-east-1.amazonaws.com/beta";

class Upload extends React.Component {
  constructor() {
    super();
    this.state = {
      files: [],
      toggle: false,
      cols: 4,
      timestamp: "",
      loadUpload: false,
      loadPredict: false,
      showPredict: false,
      showPostPredictMsg: false,
    };
    this.onDrop = (files) => {
      let id = 0;
      files.map((file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          Object.assign(file, { src: e.target.result, id: id });
          this.setState({ files: this.state.files.concat(file) });
          id += 1;
        };
      });
    };
  }

  //=============== Upload Images to S3 and Update Databases =================
  onImageUpload = async (file, timestamp) => {
    console.log(file);
    await Storage.put(
      `${this.props.service}/${timestamp}/inputs/${file.path}`,
      file,
      {
        contentType: "image/*",
        level: "protected",
        progressCallback(progress) {
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      }
    )
      .then((result) => {
        // console.log(result);
        // Object.assign(file, { key: result.key });
        const idx = this.state.files.findIndex((f) => f == file);
        let newFiles = [...this.state.files];
        newFiles[idx] = { ...newFiles[idx], key: result.key };
        this.setState({ files: newFiles });
      })
      .catch((err) => console.log(err));
  };

  onBatchUpload = async () => {
    const files = this.state.files;
    this.setState({ loadUpload: true });

    for (let i = 0; i < files.length; i++) {
      if (i < files.length - 1) {
        await this.onImageUpload(files[i], this.props.sessId);
      } else {
        await this.onImageUpload(files[i], this.props.sessId);
        await this.updateDB();
      }
    }
  };

  updateDB = async () => {
    // Updating the Service Table
    let keys = this.state.files.map((file) => file.key);
    const responseBody = serviceDB(this.props.service, this.props.sessId, keys);
    // console.log("Response:", responseBody);

    // Updating the Session Table
    const timestamp = new Date().toString();
    this.setState({ timestamp: timestamp });

    const sess_responseBody = sessionDBWrite(
      this.props.service,
      this.props.sessId,
      timestamp,
      "Uploaded"
    );
    // console.log("Session Response:", sess_responseBody);
    this.setState({ loadUpload: false, showPredict: true });
    this.props.getStatusDB();
  };

  //=============== Assign Prediction Job =================
  onPredict = async () => {
    this.setState({ loadPredict: true });

    let keys = this.state.files.map((file) => file.key);
    // console.log(keys);

    // Updating the Session Table
    const sess_responseBody = await sessionDBWrite(
      this.props.service,
      this.props.sessId,
      this.state.timestamp,
      "InProgress"
    );
    // console.log("Session Response:", sess_responseBody);

    // Making the prediction request
    const responseBody = serviceAPI(
      this.props.service,
      this.props.sessId,
      keys
    );
    // console.log("Response:", responseBody);

    this.setState({ loadPredict: false, showPostPredictMsg: true });
    this.props.getStatusDB();
  };

  render() {
    const files = this.state.files.map((file) => {
      // console.log(file.path);
      if (this.state.toggle == false) {
        this.setState({ toggle: true });
      }

      return (
        <GridListTile key={file.id}>
          <img src={file.src} alt={file.path} />
          <GridListTileBar
            title={file.path}
            actionIcon={
              <IconButton
                aria-label={`${file.path}`}
                onClick={() => {
                  this.setState((state) => {
                    var index = state.files.indexOf(file);
                    const images = state.files;

                    if (index > -1) {
                      images.splice(index, 1);
                    }
                    // console.log(images.length);
                    if (images.length == 0) {
                      this.setState({ toggle: false });
                    }
                    return {
                      images,
                    };
                  });
                }}
              >
                <i className="ui red trash icon" />
              </IconButton>
            }
          />
        </GridListTile>
      );
    });

    return (
      <div>
        <Segment>
          <h3>Session Details:</h3>
          <h4>Session ID: {this.props.sessId}</h4>
          <h4>No. of Images: {this.state.files.length}</h4>
        </Segment>
        <Dropzone onDrop={this.onDrop} type="file" accept=".png,.jpg,.jpeg">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps({
                  className: "ui placeholder segment",
                })}
              >
                <div className="ui icon header">
                  <AddPhotoAlternateIcon style={{ fontSize: 50 }} />
                  <br />
                  Drop or Click to Upload Radiological Images
                </div>
                <input {...getInputProps()} />
              </div>
            </section>
          )}
        </Dropzone>

        {this.state.toggle && (
          <div>
            <div className="ui segment">
              <GridList
                cols={this.state.cols}
                style={{
                  flexWrap: "nowrap",
                  transform: "translateZ(0)",
                }}
              >
                {files}
              </GridList>
            </div>

            {this.state.loadUpload && (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Uploading...</Message.Header>
                  Uploading your images to our server
                </Message.Content>
              </Message>
            )}
            {this.state.loadPredict && (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Initializing...</Message.Header>
                  Starting a Prediction Job
                </Message.Content>
              </Message>
            )}
            {this.state.showPostPredictMsg && (
              <Message icon positive>
                <Icon name="check" />
                <Message.Content>
                  <Message.Header>Prediction Job Started</Message.Header>
                  You can end the session and check the status in the dashboard
                  above
                </Message.Content>
              </Message>
            )}
            <center>
              <div className="ui teal button" onClick={this.onBatchUpload}>
                Upload Files
              </div>
              {this.state.showPredict && (
                <div className="ui primary button" onClick={this.onPredict}>
                  Predict
                </div>
              )}
            </center>
          </div>
        )}
      </div>
    );
  }
}

export default Upload;
