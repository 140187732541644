import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Form } from "semantic-ui-react";
import LSData from "./LSData";

const ResultsLS = (props) => {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    setDatas(props.pred.results);
  }, []);

  return (
    <div>
      <Form style={{ marginTop: "20px" }}>
        <Form.Field>
          <label style={{ fontSize: "14px" }}>
            Showing Results for the following search query:
          </label>
          <input
            type="text"
            readOnly
            defaultValue={props.pred.inputs}
            style={{ borderColor: "#22d1ee" }}
          />
        </Form.Field>
      </Form>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>SOURCE</TableCell>
              <TableCell align="center">TITLE</TableCell>
              <TableCell align="center">PUBLISHED ON</TableCell>
              <TableCell align="center">AUTHORS</TableCell>
              <TableCell align="center">JOURNAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas.map((data) => (
              <>
                <LSData
                  key={data.abstract}
                  id={data.id}
                  abstract={data.abstract}
                  source={data.source_x}
                  title={data.title}
                  publishTime={data.publish_time}
                  authors={data.authors}
                  journal={data.journal}
                  urls={data.url}
                />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ResultsLS;
