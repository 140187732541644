import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";

import Logo from "../../assets/Logo-Color.png";

import { Auth } from "aws-amplify";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cellstrat.com/">
        CellStrat
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    height: 50,
    width: "auto",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [error, setError] = React.useState({ isErr: false, msg: "" });
  const [load, setLoad] = React.useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={Logo} alt="logo" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={async (e) => {
            e.preventDefault();

            const data = new FormData(e.target);

            setError({ isErr: false, msg: "" });
            // console.log("Logging In");

            try {
              setLoad(true);

              const user = await Auth.signIn(
                data.get("username"),
                data.get("password")
              );
              window.location.reload();
            } catch (error) {
              setError({ isErr: true, msg: error.message });
              setLoad(false);
              // console.log(error.message);
            }
          }}
        >
          {props.signUpSuccess && (
            <div className="ui positive message">
              <div className="header">Success</div>
              <p>
                Sign Up Successful. You may login with your credentials now.
              </p>
            </div>
          )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={error.isErr}
            helperText={error.msg}
            autoComplete="current-password"
          />
          <div className={classes.submit}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Sign In
            </Button>
            {load && <LinearProgress />}
          </div>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={props.showReset}
                style={{ cursor: "pointer" }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                variant="body2"
                onClick={props.toggleForms}
                style={{ cursor: "pointer" }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
