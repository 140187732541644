export const createRows = (responseBody) => {
  let row_data = [];
  for (var sessId in responseBody) {
    if (sessId !== "message") {
      let row = {
        sessId: sessId,
        timestamp: responseBody[sessId].timestamp,
        stat: responseBody[sessId].stat,
      };
      row_data.push(row);
    } else {
      row_data = [];
    }
  }
  return row_data;
};

export const createResultsList = (responseBody) => {
  let predictions = [];
  for (var key in responseBody) {
    if (key !== "message") {
      let pred = {
        key: key,
        ...responseBody[key],
      };
      predictions.push(pred);
    } else {
      predictions = [];
    }
  }
  // console.log(predictions);
  return predictions;
};
