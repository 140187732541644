import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [error, setError] = React.useState({ isErr: false, msg: "" });
  const [load, setLoad] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>

        <form
          className={classes.form}
          onSubmit={async (e) => {
            e.preventDefault();
            const data = new FormData(e.target);

            let username = data.get("username");
            let password = data.get("password");
            let email = data.get("email");

            try {
              setLoad(true);
              const user = await Auth.signUp({
                username,
                password,
                attributes: {
                  email,
                },
              });
              props.showConfirm(username);
            } catch (error) {
              setError({ isErr: true, msg: error.message });
              setLoad(false);
              // console.log(error.message);
            }
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={false}
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={(e) => {
                  if (e.target.checked) {
                    setAgree(true);
                    alert(
                      "Disclaimer: The Medical AI technology available on this platform is experimental and should NOT be used to make medical decisions for any patient."
                    );
                  } else {
                    setAgree(false);
                  }
                }}
              />
            }
            label="I understand that the healthcare AI is experimental"
          />
          {error.isErr && (
            <div className="ui negative message">
              <div className="header">Error</div>
              <p>{error.msg}</p>
            </div>
          )}
          {agree ? (
            <div className={classes.submit}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign Up
              </Button>
              {load && <LinearProgress />}
            </div>
          ) : (
            <div className={classes.submit}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled
              >
                Sign Up
              </Button>
            </div>
          )}

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={props.toggleForms}
                style={{ cursor: "pointer" }}
              >
                Have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
