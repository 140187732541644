import React, { useRef, Component, PropTypes } from "react";
import Navbar from "./components/Navbar";
import Upload from "./components/Vision/Upload";
import Results from "./components/Vision/Results";
import SessionTable from "./components/Vision/SessionTable";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import PrintResults from "./components/PrintResults";
import {
  Progress,
  Grid,
  Message,
  Icon,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";
import Amplify, { Storage, Auth } from "aws-amplify";
import {
  serviceDB,
  sessionDBWrite,
  sessionDBRead,
  resultsDB,
  serviceAPI,
} from "./components/API";
import { createRows, createResultsList } from "./components/Vision/Utils";

class MalariaDetection extends React.Component {
  state = {
    predictions: [],
    length: 0,
    res: false,
    loading: false,
    isSess: false,
    sessId: "",
    rows: [],
  };

  componentDidMount() {
    this.getStatusDB();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  //============= Session ================

  getStatusDB = async () => {
    // Read Session Data
    const responseBody = await sessionDBRead("malaria", this.state.sessId);
    // console.log(responseBody);
    // Format the Response as Row Data for the SessionTable
    const rows = await createRows(responseBody);
    // Sort rows
    await rows.sort(function (a, b) {
      let dateA = a.sessId.toLowerCase();
      let dateB = b.sessId.toLowerCase();
      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
    this.setState({ rows: rows });
    // console.log("Rows Updated:",rows);
  };

  onSessionToggle = () => {
    if (!this.state.isSess) {
      let timestamp = "MB" + new Date().getTime();
      this.setState({ isSess: true, sessId: timestamp, btn: "red" });
    } else {
      let timestamp = "MB" + new Date().getTime();
      this.setState({
        isSess: false,
        sessId: timestamp,
        btn: "blue",
      });
    }
    this.getStatusDB();
  };

  //============= Results ================

  getResults = async (sessId) => {
    this.setState({ res: false });
    // Get Results
    const responseBody = await resultsDB("malaria", sessId);
    // console.log(responseBody);
    const results = await createResultsList(responseBody.results);
    this.setState({
      predictions: results,
      res: true,
      isSess: false,
      sessId: sessId,
    });
  };

  //============= Make Predictions from Dashboard ================

  dashPred = async (sessId, timestamp) => {
    // Call ResultsDB API for getting the input keys of the session
    const resultsBody = await resultsDB("malaria", sessId);
    // console.log(resultsBody);
    const keys = await resultsBody.inputs;

    // Updating the Session Table
    const sess_responseBody = await sessionDBWrite(
      "malaria",
      sessId,
      timestamp,
      "InProgress"
    );
    // console.log("Session Response:", sess_responseBody);

    // Making the prediction request
    const responseBody = serviceAPI("malaria", sessId, keys);
    // console.log("Response:", responseBody);

    this.getStatusDB();
  };

  render() {
    const results = this.state.predictions.map((pred) => {
      let classes = [
        { name: "Infected", pred: pred.Infected, color: "red" },
        { name: "Not Infected", pred: pred.Uninfected, color: "green" },
      ];
      let output = { isOutput: false, label: "" };
      return <Results pred={pred} classes={classes} output={output} />;
    });

    return (
      <div>
        <Navbar>
          <h1>Malaria Detection from Microscopic Blood Sample Images</h1>
          <Divider horizontal>
            <Header as="h2">
              <Icon name="bar chart" />
              Dashboard
            </Header>
          </Divider>
          <Button icon labelPosition="left" onClick={this.getStatusDB}>
            Refresh
            <Icon name="sync alternate" />
          </Button>

          <SessionTable
            rows={this.state.rows}
            view={this.getResults}
            predict={this.dashPred}
          />

          <div
            ref={(el) => {
              this.el = el;
            }}
          />
          <Divider horizontal>
            <Header as="h2">
              <Icon name="edit" />
              Workspace
            </Header>
          </Divider>

          {!this.state.res && (
            <Button
              icon
              labelPosition="left"
              color={this.state.isSess ? "red" : "blue"}
              onClick={this.onSessionToggle}
            >
              {!this.state.isSess ? (
                <div>Start Session</div>
              ) : (
                <div>End Session</div>
              )}
              {!this.state.isSess ? <Icon name="play" /> : <Icon name="stop" />}
            </Button>
          )}

          {this.state.isSess && (
            <Segment raised>
              <Upload
                sessId={this.state.sessId}
                toggle={this.onSessionToggle}
                getStatusDB={this.getStatusDB}
                service="malaria"
              />
            </Segment>
          )}

          {this.state.res && (
            <Segment raised>
              <IconButton
                style={{
                  right: "15px",
                  position: "absolute",
                }}
                color="secondary"
                onClick={() =>
                  this.setState({ res: false, isSess: false, btn: "blue" })
                }
              >
                <CloseIcon />
              </IconButton>

              <div>
                <PrintResults
                  title="Malaria Detection from Blood Sample Image Results"
                  sessId={this.state.sessId}
                  service="malaria"
                >
                  <Grid stackable id="MalariaResults" columns={3}>
                    {results}
                  </Grid>
                </PrintResults>
              </div>
            </Segment>
          )}
        </Navbar>
      </div>
    );
  }
}

export default MalariaDetection;
