import React from "react";
import { Form } from "semantic-ui-react";

export default class ResultsTS extends React.Component {
  render() {
    return (
      <div>
        <Form>
          <Form.Field>
            <label style={{ fontSize: "14px" }}>Input Text</label>
            <textarea
              readOnly
              defaultValue={this.props.pred.inputs}
              style={{
                height: "200px",
                padding: "10px",
                borderColor: "orange",
              }}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontSize: "14px", marginTop: "20px" }}>
              Summarized Text
            </label>
            <textarea
              readOnly
              defaultValue={this.props.pred.results[0]}
              style={{
                height: "200px",
                padding: "10px",
                borderColor: "#fc5185",
              }}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}
