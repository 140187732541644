import React from "react";
import SignIn from "./components/Account/SignIn";
import SignUp from "./components/Account/SignUp";
import SignUpConfirm from "./components/Account/SignUpConfirm";
import ForgotPwd from "./components/Account/ForgotPwd";

import { Auth } from "aws-amplify";

export default function Authenticator(App) {
  class AuthenticatedComponent extends React.Component {
    state = {
      signIn: true,
      authState: false,
      username: "",
      showConfirm: false,
      showReset: false,
      signUpSuccess: false,
    };

    async componentDidMount() {
      try {
        let user = await Auth.currentAuthenticatedUser();
        this.setState({ authState: true });
      } catch (error) {
        console.log(error);
        this.setState({ authState: false });
      }
    }

    toggleForms = () => {
      this.setState({ signIn: !this.state.signIn, signUpSuccess: false });
    };

    showConfirm = (username) => {
      this.setState({ username: username, showConfirm: true });
    };

    postSignUp = () => {
      this.setState({ signIn: true, showConfirm: false, signUpSuccess: true });
    };

    showReset = () => {
      this.setState({ showReset: true, signIn: false });
    };

    render() {
      if (this.state.authState) {
        return <App />;
      } else if (
        this.state.signIn &&
        !this.state.showConfirm &&
        !this.state.showReset
      ) {
        return (
          <SignIn
            toggleForms={this.toggleForms}
            showReset={this.showReset}
            signUpSuccess={this.state.signUpSuccess}
          />
        );
      } else if (
        !this.state.signIn &&
        !this.state.showConfirm &&
        !this.state.showReset
      ) {
        return (
          <SignUp
            toggleForms={this.toggleForms}
            showConfirm={this.showConfirm}
          />
        );
      } else if (this.state.showConfirm) {
        return (
          <SignUpConfirm
            username={this.state.username}
            goBack={this.postSignUp}
          />
        );
      } else if (this.state.showReset) {
        return (
          <ForgotPwd
            goHome={() => {
              this.setState({ signIn: true, showReset: false });
            }}
          />
        );
      }
    }
  }
  return AuthenticatedComponent;
}
