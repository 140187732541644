import { Height } from "@material-ui/icons";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


// Import react-circular-progressbar module and styles

function DPVis(props) {
  let percentage = props.value;
  console.log(props.disease);

  return (
    <Content label={`${props.disease}`}>
      <CircularProgressbar value={percentage} text={`${percentage}%` } />
    </Content>
  );
}

function Content(props) {
  return (
    <div style={{ marginBottom: 80,display: "flex", flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"}}>
      <div style={{ marginTop: 0, display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",width: "40%"}}>
        <div style={{flex:"1" }}>{props.children}</div>
            <div style={{ width: "100%",flex:1,display:"flex",width:"auto",marginTop: 10 }}>
          <h4 style={{width: "max-content"}}>{props.label}</h4>
        </div>
    
      </div>
    </div>
  );
}

export default DPVis;
