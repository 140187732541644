import React from "react";
import Navbar from "./components/Navbar";
import { useHistory } from "react-router-dom";
// import './components/styles.css';

export default function Home(props) {
  let history = useHistory();

  return (
    <Navbar>
      <h1>Home</h1>
      <div className="ui cards">
        <div className="card">
          <div className="content">
            <div className="header">X-Ray Analyzer</div>
            <div className="description">
              Scan Chest X-Ray Images to detect Covid-19, Pneumonia and Normal
              ones
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/xray")}
          >
            Go To X-Ray Analyzer
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Diabetic Retinopathy</div>
            <div className="description">
              Scan Diabetic Retinopathy Images and Get the results Instantly.
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/pdr")}
          >
            Go To Retina Scanner
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">CT Scan</div>
            <div className="description">
              Upload CT Scan Reports and Get the results
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/chestct")}
          >
            Go To CT Analyser
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Brain Tumour Classifier</div>
            <div className="description">
              Upload Brain MRIs and analyse it for tumours
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/braintumor")}
          >
            Go To Brain Tumour Classifier
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Skin Cancer Classifier</div>
            <div className="description">
              Upload Skin Cancer Images to classify them
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/skincancer")}
          >
            Go To Skin Cancer Classifier
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Malaria Detector</div>
            <div className="description">
              Upload Microscopic Blood Sample Images to detect Malaria
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/malariadetection")}
          >
            Go To Malaria Detector
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Medical Text Summarization</div>
            <div className="description">
              Summarize medical literature in a click
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/textsum")}
          >
            Go To Medical Text Summarization
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Entity Recognition</div>
            <div className="description">
              Tag medical entities in pharma literature
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/ner")}
          >
            Go To Entity Recognition
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Disease Prediction</div>
            <div className="description">
              Predict the disease from a piece of text
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/diseasepred")}
          >
            Go To Disease Prediction
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Covid-19 Literature Search</div>
            <div className="description">
              Search for literature for Covid-19
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/litsearch")}
          >
            Go To Literature Search
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Drug Safety Reporting</div>
            <div className="description">
              Adverse Event Reporting for Clinical Trials
            </div>
          </div>
          <div
            className="ui bottom attached button"
            onClick={() => history.push("/drugsafety")}
          >
            Go To Drug Safety Reporting Tool
            <i className="right arrow icon" />
          </div>
        </div>

        <div className="card">
          <div className="content">
            <div className="header">Account Settings</div>
            <div className="description">
              Update your personal information and change your password
            </div>
          </div>

          <div
            className="ui bottom attached button"
            onClick={() => history.push("/account")}
          >
            Go To Account Settings
            <i className="right arrow icon" />
          </div>
        </div>
      </div>
    </Navbar>
  );
}
