import React from "react";
import { Auth } from "aws-amplify";
import ReactToPrint from "react-to-print";
import { sessionDBRead } from "./API";

class PrintResults extends React.Component {
  state = { vis: false, username: "", timestamp: "" };

  async componentWillMount() {
    const user = await Auth.currentAuthenticatedUser();

    const responseBody = await sessionDBRead(
      this.props.service,
      this.props.sessId
    );
    let timestamp = responseBody[this.props.sessId].timestamp;

    this.setState({ username: user.username, timestamp: timestamp });
  }

  render() {
    return (
      <div>
        <ReactToPrint
          documentTitle={this.props.sessId}
          pageStyle={{ padding: "100px" }}
          onBeforeGetContent={() => this.setState({ vis: true })}
          onAfterPrint={() => this.setState({ vis: false })}
          trigger={() => {
            return (
              <button
                style={{
                  left: "15px",
                  position: "absolute",
                }}
                className="ui labeled icon button"
              >
                <i className="download icon"></i>
                Download Results
              </button>
            );
          }}
          content={() => this.componentRef}
        />

        <div ref={(el) => (this.componentRef = el)}>
          <center>
            <h2>{this.props.title}</h2>
          </center>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            {this.state.vis && (
              <table className="ui very basic compact table">
                <tbody>
                  <tr>
                    <th>Username:</th>
                    <td>{this.state.username}</td>
                  </tr>
                  <tr>
                    <th>Session ID:</th>
                    <td>{this.props.sessId}</td>
                  </tr>
                  <tr>
                    <th>Creation Time:</th>
                    <td>{this.state.timestamp}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PrintResults;
