import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NERVis from "./NERVis";

const ResultsNER = (props) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    setDatas(props.pred.results.slice(1, -1));
  }, []);

  return (
    <Card variant="outlined" style={{ marginTop: "20px" }}>
      <CardContent>
        <Typography variant="body2" component="p">
          {datas.map((data) => (
            <NERVis tag={data.tag} token={data.token} />
          ))}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ResultsNER;
