import React from "react";
import {
  Grid,
  Segment,
  Image,
  Button,
  Card,
  List,
  Progress,
  Checkbox,
} from "semantic-ui-react";
import Amplify, { Storage, Auth } from "aws-amplify";
import { LinearProgress } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

export default class Results extends React.Component {
  state = { src: "", img: "", outputImg: "" };

  componentWillMount() {
    Storage.get(this.props.pred.key, { level: "protected" })
      .then((result) => {
        // console.log(result);
        this.setState({ src: result, img: result });
      })
      .catch((err) => console.log(err));

    Storage.get(this.props.pred.outputImg, { level: "protected" })
      .then((result) => {
        // console.log(result);
        this.setState({ outputImg: result });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const classes = this.props.classes.map((cls) => {
      const BorderLinearProgress = withStyles((theme) => ({
        root: {
          height: 27,
          borderRadius: 3,
        },
        colorPrimary: {
          backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
        },
        bar: {
          borderRadius: 3,
          backgroundColor: `${cls.color}`,
        },
      }))(LinearProgress);
      return (
        <div style={{ marginBottom: 7 }}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <h3>
              {cls.name}: {cls.pred}%
            </h3>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={parseFloat(cls.pred)}
            style={{
              bar: {
                borderRadius: 5,
                backgroundColor: "black",
              },
            }}
          />
        </div>
      );
    });
    return (
      <Grid.Column>
        <Card style={{ width: "40vh" }}>
          <Image src={this.state.src} style={{ height: "300px" }} />
          <Card.Content>
            <Card.Header>Predicted: {this.props.pred.class}</Card.Header>
            <Card.Meta>{this.props.pred.key.split("/")[3]}</Card.Meta>
            <Card.Description>{classes}</Card.Description>
          </Card.Content>
          {this.props.output.isOutput && (
            <Card.Content extra>
              <Checkbox
                toggle
                id={this.props.pred.key}
                label={<label>{this.props.output.label}</label>}
                onChange={() => {
                  let toggle = document.getElementById(this.props.pred.key);
                  if (toggle.checked) {
                    this.setState({ src: this.state.outputImg });
                  } else {
                    this.setState({ src: this.state.img });
                  }
                }}
              />
            </Card.Content>
          )}
        </Card>
      </Grid.Column>
    );
  }
}
