import React from "react";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import Logo from "../assets/Logo-Color.png";
import { Icon } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import { Auth } from "aws-amplify";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#F5F5F5",
  },

  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: 260,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#0E153A",
  },
  drawerContainer: {
    overflow: "auto",
  },
  divider: {
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "20px",
    marginRight: "20px",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  tree: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginLeft: "-10px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "transparent",
    color: "white",
  },
  treeText: {
    marginLeft: "10px",
    fontSize: "15px",
  },
  subtree: {
    padding: theme.spacing(0.75),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // function handleClick() {
  //   history.push("/home");
  // }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const Item = (props) => (
    <div>
      <Icon name={props.icon} size="large" />
      <label className={classes.treeText}>{props.text}</label>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="#6C6C6C"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            <Link to="/">
              <img
                className="ui left small image"
                src={Logo}
                alt="Logo"
                style={{
                  backgroundColor: "#F5F5F5",
                }}
              />
            </Link>
          </Typography>
          <Tooltip title="Sign Out" arrow>
            <a href="/" style={{ marginLeft: "auto" }}>
              <IconButton
                onClick={async () => {
                  try {
                    await Auth.signOut();
                    // console.log("Signed out successfully");
                    history.push("/");
                    window.location.reload();
                  } catch (error) {
                    // console.log("error signing out: ", error);
                  }
                }}
              >
                <ExitToAppIcon style={{ fontSize: 30 }} />
              </IconButton>
            </a>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="home" text="Home" id="text" />}
                  onClick={() => history.push("/")}
                />
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="user" text="My Account" />}
                  onClick={() => history.push("/account")}
                />
              </TreeView>
              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <div className={classes.divider}>
                  Technology
                  <hr
                    style={{
                      backgroundColor: "white",
                      opacity: "50%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="eye" text="Vision" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="3"
                    label="X-Ray Analyser"
                    onClick={() => history.push("/xray")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Diabetic Retinopathy"
                    onClick={() => history.push("/pdr")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="CT Scan"
                    onClick={() => history.push("/chestct")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Brain Tumour Classifier"
                    onClick={() => history.push("/braintumor")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Skin Cancer Classifier"
                    onClick={() => history.push("/skincancer")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Malaria Detection"
                    onClick={() => history.push("/malariadetection")}
                  />
                </TreeItem>
                <TreeItem
                  className={classes.subtree}
                  nodeId="5"
                  label={<Item icon="language" text="NLP" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="6"
                    label="Text Summarization"
                    onClick={() => history.push("/textsum")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="7"
                    label="Disease Prediction"
                    onClick={() => history.push("/diseasepred")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="8"
                    label="Named Entity Recognition"
                    onClick={() => history.push("/ner")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="9"
                    label="Covid-19 Literature Search"
                    onClick={() => history.push("/litsearch")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="10"
                    label="Drug Safety Reporting"
                    onClick={() => history.push("/drugsafety")}
                  />
                </TreeItem>
                {/*}<TreeItem
                  className={classes.subtree}
                  nodeId="6"
                  label={<Item icon="gamepad" text="Reinforcement Learning" />}
                />*/}
              </TreeView>

              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <div className={classes.divider}>
                  Industry
                  <hr
                    style={{
                      backgroundColor: "white",
                      opacity: "50%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="heartbeat" text="Healthcare" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="2"
                    label="Covid-19"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="3"
                      label="X-Ray Analyser"
                      onClick={() => history.push("/xray")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="CT Scan"
                      onClick={() => history.push("/chestct")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="8"
                      label="Covid-19 Literature Search"
                      onClick={() => history.push("/litsearch")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="5"
                    label="Ophthalmology"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="6"
                      label="Proliferative Diabetic Retinopathy"
                      onClick={() => history.push("/pdr")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="11"
                    label="Cancer Prediction"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Brain Tumour Classifier"
                      onClick={() => history.push("/braintumor")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Skin Cancer Classifier"
                      onClick={() => history.push("/skincancer")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="12"
                    label="Microscopic Blood Sample Imagery"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Malaria Detection"
                      onClick={() => history.push("/malariadetection")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="7"
                    label="Medical Literature"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="8"
                      label="Text Summarization"
                      onClick={() => history.push("/textsum")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="9"
                      label="Disease Prediction"
                      onClick={() => history.push("/diseasepred")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="10"
                      label="Named Entity Recognition"
                      onClick={() => history.push("/ner")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="11"
                      label="Drug Safety Reporting"
                      onClick={() => history.push("/drugsafety")}
                    />
                  </TreeItem>
                </TreeItem>
                {/*<TreeItem
              className={classes.subtree}
              nodeId="4"
              label={<Item icon="shopping cart" text="Retail" />}
            />
            <TreeItem
              className={classes.subtree}
              nodeId="5"
              label={<Item icon="dollar" text="Finance" />}
            />*/}
              </TreeView>
            </div>
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="home" text="Home" />}
                  onClick={() => history.push("/")}
                />
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="user" text="My Account" />}
                  onClick={() => history.push("/account")}
                />
              </TreeView>
              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <div className={classes.divider}>
                  Technology
                  <hr
                    style={{
                      backgroundColor: "white",
                      opacity: "50%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="eye" text="Vision" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="3"
                    label="X-Ray Analyser"
                    onClick={() => history.push("/xray")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Diabetic Retinopathy"
                    onClick={() => history.push("/pdr")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="CT Scan"
                    onClick={() => history.push("/chestct")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Brain Tumour Classifier"
                    onClick={() => history.push("/braintumor")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Skin Cancer Classifier"
                    onClick={() => history.push("/skincancer")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="4"
                    label="Malaria Detection"
                    onClick={() => history.push("/malariadetection")}
                  />
                </TreeItem>
                <TreeItem
                  className={classes.subtree}
                  nodeId="5"
                  label={<Item icon="language" text="NLP" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="6"
                    label="Text Summarization"
                    onClick={() => history.push("/textsum")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="7"
                    label="Disease Prediction"
                    onClick={() => history.push("/diseasepred")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="8"
                    label="Named Entity Recognition"
                    onClick={() => history.push("/ner")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="9"
                    label="Covid-19 Literature Search"
                    onClick={() => history.push("/litsearch")}
                  />
                  <TreeItem
                    className={classes.subtree}
                    nodeId="10"
                    label="Drug Safety Reporting"
                    onClick={() => history.push("/drugsafety")}
                  />
                </TreeItem>
                {/*}<TreeItem
                  className={classes.subtree}
                  nodeId="6"
                  label={<Item icon="gamepad" text="Reinforcement Learning" />}
                />*/}
              </TreeView>

              <TreeView
                className={classes.tree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <div className={classes.divider}>
                  Industry
                  <hr
                    style={{
                      backgroundColor: "white",
                      opacity: "50%",
                      borderColor: "transparent",
                    }}
                  />
                </div>
                <TreeItem
                  className={classes.subtree}
                  nodeId="1"
                  label={<Item icon="heartbeat" text="Healthcare" />}
                >
                  <TreeItem
                    className={classes.subtree}
                    nodeId="2"
                    label="Covid-19"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="3"
                      label="X-Ray Analyser"
                      onClick={() => history.push("/xray")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="CT Scan"
                      onClick={() => history.push("/chestct")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="9"
                      label="Covid-19 Literature Search"
                      onClick={() => history.push("/litsearch")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="5"
                    label="Ophthalmology"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="6"
                      label="Proliferative Diabetic Retinopathy"
                      onClick={() => history.push("/pdr")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="11"
                    label="Cancer Prediction"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Brain Tumour Classifier"
                      onClick={() => history.push("/braintumor")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Skin Cancer Classifier"
                      onClick={() => history.push("/skincancer")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="12"
                    label="Microscopic Blood Sample Imagery"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="4"
                      label="Malaria Detection"
                      onClick={() => history.push("/malariadetection")}
                    />
                  </TreeItem>
                  <TreeItem
                    className={classes.subtree}
                    nodeId="7"
                    label="Medical Literature"
                  >
                    <TreeItem
                      className={classes.subtree}
                      nodeId="8"
                      label="Text Summarization"
                      onClick={() => history.push("/textsum")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="9"
                      label="Disease Prediction"
                      onClick={() => history.push("/diseasepred")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="10"
                      label="Named Entity Recognition"
                      onClick={() => history.push("/ner")}
                    />
                    <TreeItem
                      className={classes.subtree}
                      nodeId="10"
                      label="Drug Safety Reporting"
                      onClick={() => history.push("/drugsafety")}
                    />
                  </TreeItem>
                </TreeItem>
                {/*<TreeItem
              className={classes.subtree}
              nodeId="4"
              label={<Item icon="shopping cart" text="Retail" />}
            />
            <TreeItem
              className={classes.subtree}
              nodeId="5"
              label={<Item icon="dollar" text="Finance" />}
            />*/}
              </TreeView>
            </div>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} id="main">
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
}
