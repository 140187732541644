import React from "react";
import DPVis from "./DPVis";
import { Form, Card, Grid } from "semantic-ui-react";

class ResultsDP extends React.Component {
  componentWillMount() {
    let arr = [];
    let mapping = {};

    const inputs = this.props.pred.inputs;
    const results = this.props.pred.results;

    for (var i in results) {
      arr.push(results[i]);
    }

    for (let i in results) {
      mapping[results[i]] = i;
    }

    arr.sort((a, b) => b - a);

    arr = arr.slice(0, 5);

    let mapp = {};
    let arr1 = [];

    for (let i = 0; i < arr.length; i++) {
      arr1.push(mapping[arr[i]]);
    }
    this.setState({ probs: arr, labels: arr1 });
  }

  render() {
    let cards = [];
    for (let i = 0; i < this.state.probs.length; i++) {
      cards.push(
        <Grid.Column>
          <Card raised style={{ width: "50vh", height: "30vh" }}>
            <Card.Content>
              <DPVis
                value={this.state.probs[i]}
                disease={this.state.labels[i]}
              />
            </Card.Content>
          </Card>
        </Grid.Column>
      );
    }

    return (
      <div style={{ marginTop: "20px" }}>
        <Form>
          <Form.Field>
            <h3>Input Text</h3>
            <textarea
              readOnly
              defaultValue={this.props.pred.inputs}
              style={{
                height: "100px",
                padding: "10px",
              }}
            />
          </Form.Field>
        </Form>
        <h3 style={{ textAlign: "center" }}>Top - 5 Predictions</h3>
        <Grid stackable columns={3}>
          {cards}
        </Grid>
      </div>
    );
  }
}

// function ResultsDP(props) {
//   let arr = [];
//   let mapping = {};
//
//   const inputs = props.pred.inputs;
//   const results = props.pred.results;
//
//   for (var i in results) {
//     arr.push(results[i]);
//   }
//
//   for (let i in results) {
//     mapping[results[i]] = i;
//   }
//
//   arr.sort((a, b) => b - a);
//
//   arr = arr.slice(0, 5);
//
//   let mapp = {};
//   let arr1 = [];
//
//   for (let i = 0; i < arr.length; i++) {
//     arr1.push(mapping[arr[i]]);
//   }
//
//   return (
//     <div>
//       <Form>
//         <Form.Field>
//           <label style={{ fontSize: "14px" }}>Input Text</label>
//           <textarea
//             readOnly
//             defaultValue={inputs}
//             style={{
//               height: "100px",
//               padding: "10px",
//               borderColor: "orange",
//             }}
//           />
//         </Form.Field>
//       </Form>
//       <Grid stackable columns={3} style={{ marginTop: 40 }}>
//         <Card variant="outlined" style={{ marginTop: "20px" }}>
//           <CardContent>
//             <DPVis value={arr[0]} disease={arr1[0]} />
//             <DPVis value={arr[1]} disease={arr1[1]} />
//             <DPVis value={arr[2]} disease={arr1[2]} />
//             <DPVis value={arr[3]} disease={arr1[3]} />
//             <DPVis value={arr[4]} disease={arr1[4]} />
//           </CardContent>
//         </Card>
//       </Grid>
//     </div>
//   );
// }

export default ResultsDP;
