import React from "react";
import Navbar from "./components/Navbar";
import { Grid } from "semantic-ui-react";
import AccountInfo from "./components/Account/AccountInfo";
import ChangePassword from "./components/Account/ChangePassword";
import { Auth } from "aws-amplify";

class Account extends React.Component {
  state = { userInfo: {} };

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;
    const email = await user.attributes.email;
    const phone = await user.attributes.phone_number;
    const name = await user.attributes.name;

    this.setState({
      userInfo: { username: username, email: email, phone: phone, name: name }
    });
  }

  render() {
    return (
      <div>
        <Navbar>
          <h1>My Account</h1>
          <Grid stackable columns={2}>
            <AccountInfo userInfo={this.state.userInfo} />
            <ChangePassword userInfo={this.state.userInfo} />
          </Grid>
        </Navbar>
      </div>
    );
  }
}

export default Account;
