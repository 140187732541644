import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Views/Home";
import XRay from "./Views/XRay";
import PDR from "./Views/PDR";
import P404 from "./Views/P404";
import ChestCT from "./Views/ChestCT";
import Authenticator from "./Views/Authenticator";
import Account from "./Views/Account";
import TextSum from "./Views/TextSum";
import DiseasePred from "./Views/DiseasePred";
import NER from "./Views/NER";
import LitSearch from "./Views/LitSearch";
import BrainTumor from "./Views/BrainTumor";
import SkinCancer from "./Views/SkinCancer";
import DrugSafety from "./Views/DrugSafety";
import MalariaDetection from "./Views/MalariaDetection";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

const NoMatchPage = () => {
  return <P404 />;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/xray" render={() => <XRay />} />
          <Route exact={true} path="/pdr" render={() => <PDR />} />
          <Route exact={true} path="/" render={() => <Home />} />
          <Route exact={true} path="/account" render={() => <Account />} />
          <Route exact={true} path="/chestct" render={() => <ChestCT />} />
          <Route
            exact={true}
            path="/braintumor"
            render={() => <BrainTumor />}
          />
          <Route
            exact={true}
            path="/skincancer"
            render={() => <SkinCancer />}
          />
          <Route
            exact={true}
            path="/malariadetection"
            render={() => <MalariaDetection />}
          />

          <Route
            exact={true}
            path="/drugsafety"
            render={() => <DrugSafety />}
          />
          <Route exact={true} path="/textsum" render={() => <TextSum />} />
          <Route
            exact={true}
            path="/diseasepred"
            render={() => <DiseasePred />}
          />
          <Route exact={true} path="/ner" render={() => <NER />} />
          <Route exact={true} path="/litsearch" render={() => <LitSearch />} />

          <Route component={NoMatchPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default Authenticator(App);
