import React from "react";
import styled from "styled-components";

const NERVis = ({ tag, token }) => {
  const Styles = styled.mark`
    [data-entity] {
      padding: 0.25em 0.35em;
      margin: 0px 0.25em;
      line-height: 1;
      display: inline-block;
      border-radius: 0.25em;
      border: 1px solid;
    }

    [data-entity]::after {
      box-sizing: border-box;
      content: attr(data-entity);
      font-size: 0.6em;
      line-height: 1;
      padding: 0.35em;
      border-radius: 0.35em;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      margin: 0px 0px 0.1rem 0.5rem;
    }

    [data-entity][data-entity=${tag}] {
      background: rgba(166, 226, 45, 0.2);
      border-color: rgb(166, 226, 45);
    }

    [data-entity][data-entity=${tag}]::after {
      background: rgb(166, 226, 45);
    }
  `;

  return (
    <span
      style={{
        marginRight: "0.5em",
        display: "inline-block",
        fontSize: "18px",
      }}
    >
      {tag !== "O" ? (
        <Styles style={{ backgroundColor: "transparent" }}>
          <mark data-entity={`${tag}`}>{token}</mark>
        </Styles>
      ) : (
        <>{token}</>
      )}
    </span>
  );
};

export default NERVis;
