import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Segment, Button, Form } from "semantic-ui-react";
import { Auth } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  segment: {
    padding: theme.spacing(1),
    backgroundColor: "#FDFDFD",
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const [error, setError] = useState({ isErr: false, msg: "" });
  const [success, setSuccess] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [oldPasswordIcon, setOldPasswordIcon] = useState("eye slash");
  const [newPasswordIcon, setNewPasswordIcon] = useState("eye slash");

  const toggleOldPasswordVisiblity = () => {
    if (!oldPasswordShown) {
      setOldPasswordShown(true);
      setOldPasswordIcon("eye");
    } else {
      setOldPasswordShown(false);
      setOldPasswordIcon("eye slash");
    }
  };

  const toggleNewPasswordVisiblity = () => {
    if (!newPasswordShown) {
      setNewPasswordShown(true);
      setNewPasswordIcon("eye ");
    } else {
      setNewPasswordShown(false);
      setNewPasswordIcon("eye slash");
    }
  };

  return (
    <Grid.Column>
      <Segment className={classes.segment}>
        <h2>Change Password</h2>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            let old_password = data.get("old_password");
            let new_password = data.get("new_password");
            let confirm_new_password = data.get("confirm_new_password");

            if (!confirm_new_password || !old_password || !new_password) {
              setSuccess(false);
              setError({
                isErr: true,
                msg: "You must enter a valid new password to change password",
              });
            } else {
              if (new_password === confirm_new_password) {
                Auth.currentAuthenticatedUser()
                  .then((user) => {
                    return Auth.changePassword(
                      user,
                      old_password,
                      new_password
                    );
                  })
                  .then((data) => {
                    // console.log(data);
                    setSuccess(true);
                  })
                  .catch((err) => {
                    console.log(err.message);
                    setError({ isErr: true, msg: err.message });
                  });
              } else {
                setSuccess(false);
                setError({
                  isErr: true,
                  msg:
                    "Your confirmation password does not match the new password you entered.",
                });
              }
            }
          }}
        >
          <Form.Field>
            <label>Old Password</label>
            <div className="ui icon input">
              <input
                name="old_password"
                placeholder="Old Password"
                type={oldPasswordShown ? "text" : "password"}
              />
              <i
                className={`${oldPasswordIcon} link icon`}
                onClick={toggleOldPasswordVisiblity}
              ></i>
            </div>
          </Form.Field>
          <Form.Field>
            <label>New Password</label>
            <input
              name="new_password"
              placeholder="New Password"
              type={newPasswordShown ? "text" : "password"}
            />
          </Form.Field>
          <Form.Field>
            <label>Confirm New Password</label>

            <div className="ui icon input">
              <input
                name="confirm_new_password"
                placeholder="Confirm New Password"
                type={newPasswordShown ? "text" : "password"}
              />{" "}
              <i
                className={`${newPasswordIcon} link icon`}
                onClick={toggleNewPasswordVisiblity}
              ></i>
            </div>
          </Form.Field>
          {error.isErr && (
            <div className="ui negative message">
              <div className="header">Error</div>
              <p>{error.msg}</p>
            </div>
          )}
          {success && (
            <div className="ui positive message">
              <div className="header">Success</div>
              <p>Password Changed Successfully</p>
            </div>
          )}
          <Button type="submit" primary>
            Change Password
          </Button>
        </Form>
      </Segment>
    </Grid.Column>
  );
}
