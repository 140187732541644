import React from "react";
import TextField from "@material-ui/core/TextField";
import { Message, Icon, Divider, Header, Segment } from "semantic-ui-react";
import { serviceDB, sessionDBWrite, sessionDBRead, serviceAPI } from "../API";
import Button from "@material-ui/core/Button";
import Amplify, { Storage, Auth } from "aws-amplify";
import { CircularProgress } from "@material-ui/core";

// const fs = require("fs");

const API_URL = "https://oaq0lwjwp9.execute-api.us-east-1.amazonaws.com/beta";

class NLPInput extends React.Component {
  constructor() {
    super();
    this.state = {
      text: "",
      n_words: 0,
      timestamp: "",
      loadPredict: false,
      showPredict: false,
      showPostPredictMsg: false,
      userId: "",
      tmpKey: "",
      loaderState: "disabled",
    };
  }

  async componentWillMount() {
    let creds = await Auth.currentCredentials();
    const userId = creds.identityId;
    // console.log(userId);
    this.setState({ userId: userId });
  }

  countWords = (text) => {
    text = text.trim();
    text = text.replace(/\n/g, " ");
    text = text.replace(/[ ]{2,}/gi, " ");
    let n_words = text.split(" ").length;
    return n_words;
  };

  //=============== Update Inputs in Databases =================
  updateDB = async () => {
    // Updating the Service Table
    const responseBody = serviceDB(
      this.props.service,
      this.props.sessId,
      this.state.text
    );
    // console.log("Response:", responseBody);

    // Updating the Session Table
    const timestamp =
      this.state.timestamp == "" ? new Date().toString() : this.state.timestamp;
    this.setState({ timestamp: timestamp });

    const sess_responseBody = await sessionDBWrite(
      this.props.service,
      this.props.sessId,
      timestamp,
      "InProgress"
    );
    // console.log("Session Response:", sess_responseBody);

    await this.props.getStatusDB();
  };

  //=============== Start Prediction Job =================
  onPredict = async (e) => {
    e.preventDefault();
    this.setState({ loadPredict: true });
    this.updateDB();

    // Making the prediction request
    const responseBody = await serviceAPI(
      this.props.service,
      this.props.sessId,
      this.state.text
    );
    console.log("Response:", responseBody);

    this.setState({ loadPredict: false, showPostPredictMsg: true });
    await this.props.getStatusDB();
  };

  readUploadedData = async (event) => {
    var file = event.target.files[0];
    this.setState({ loaderState: "active" });

    await Storage.put(`documents/${this.props.sessId}/${file.name}`, file, {
      level: "protected",
      contentType: "file",
    })
      .then((result) => {
        let key = `protected/${this.state.userId}/${result.key}`;
        // console.log(key);
        this.setState({ tmpKey: key });
      })
      .catch((err) => console.log(err));

    const response = await fetch(`${API_URL}/file2text`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      body: this.state.tmpKey,
    });
    const text = await response.text();
    // console.log(text);
    this.setState({ text: text, loaderState: "disabled" });
  };

  resetText = () => {
    this.setState({ text: "", n_words: 0 });
  };
  render() {
    return (
      <div>
        <Segment>
          <h3>Session Details:</h3>
          <h4>Session ID: {this.props.sessId}</h4>
          <h4>No. of words: {this.state.n_words}</h4>{" "}
        </Segment>
        <form onSubmit={this.onPredict}>
          <TextField
            id="nlp-input"
            label="Enter your medical text for inference"
            multiline
            rows={12}
            variant="outlined"
            fullWidth
            style={{ marginBottom: 10 }}
            onChange={async (e) => {
              let text = e.target.value;
              await this.setState({
                text: text,
                n_words: text.trim() != "" ? this.countWords(text) : 0,
              });
            }}
            value={this.state.text}
          />

          <div>
            {this.state.loadPredict && (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Initializing...</Message.Header>
                  Starting a Prediction Job
                </Message.Content>
              </Message>
            )}
            {this.state.showPostPredictMsg && (
              <Message icon positive>
                <Icon name="check" />
                <Message.Content>
                  <Message.Header>Prediction Job Started</Message.Header>
                  You can end the session and check the status in the dashboard
                  above
                </Message.Content>
              </Message>
            )}
          </div>

          <div className={`ui ${this.state.loaderState} huge loader`}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <input
                id="contained-button-file"
                type="file"
                accept=".txt,.docx,.pdf"
                onChange={this.readUploadedData}
                style={{ display: "none" }}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  className="ui primary button"
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
            <div
              type="submit"
              className="ui primary button"
              onClick={this.onPredict}
            >
              Predict
            </div>
            {this.state.text ? (
              <Button
                variant="contained"
                color="secondary"
                component="span"
                onClick={this.resetText}
              >
                Reset
              </Button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default NLPInput;
