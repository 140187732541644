/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5426d6e3-95f9-4a10-af5b-badde744819d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3jWz3f6UI",
    "aws_user_pools_web_client_id": "3vqn35pcmboeh6o6qsqrj2h7dv",
    "oauth": {},
    "aws_user_files_s3_bucket": "cellstrat-platform174526-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
